<template>
    <div>        
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <b-button variant="primary" @click="isActiveModal = true">
                            <span class="text-nowrap">
                                Agregar
                            </span>
                        </b-button>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-input-group>
                                <b-form-input v-model="searchQuery" class="d-inline-block _mr-1" placeholder="Buscar por nombre"/>
                                <b-input-group-prepend>
                                <b-button variant="primary">
                                    <feather-icon icon="SearchIcon" />
                                </b-button>
                                </b-input-group-prepend>                                
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refRolesListTable"
                class="position-relative"
                :items="listRoles"
                responsive
                :fields="columns"
                primary-key="id"
                show-empty
                empty-text="No se encontraron resultados"
                :busy.sync="loading"
                stacked="md"
                small
            >
                <!-- Empty -->
                <template slot="empty">
                    <div v-if="loading" class="d-flex justify-content-center mb-1">
                        <b-spinner
                            style="width: 3rem; height: 3rem"
                            class="mr-1"
                            label="Loading..."
                            variant="primary"
                        />
                    </div>
                </template>

                <!-- Actions -->
                <template #cell(actions)="data">
                    <div class="demo-inline-spacing">
                        <b-button v-if="data.item.name !== 'Administrador'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning" class="btn-icon" size="sm"
                            @click="editRole(data.item)"
                            v-b-tooltip.hover.right="`Editar`">
                            <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button
                            v-if="data.item.name !== 'Administrador'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon" size="sm"
                            @click="deleteRoles(data.item)" v-b-tooltip.hover.right="`Eliminar`">
                            <feather-icon icon="TrashIcon" />
                        </b-button>
                    </div>
                </template>
            </b-table>
        </b-card>

        <!-- Modal Roles -->
        <b-modal ref="infoModalRef" id="modalCreateUpdateProducts" :title="titleModal" no-close-on-backdrop hide-footer
            @cancel="isActiveModal = false" @hidden="closeModal" :visible="isActiveModal" size="md">
            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <b-form class="p-2" ref="formRef" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row>
                        <b-col cols="12" md="12">
                            <!-- Name -->
                            <b-form-group label="Nombre" label-for="name">
                                <b-form-input :disabled="isDetail" id="name" v-model="role.name" trim
                                    placeholder="Insertar Nombre" />
                            </b-form-group>
                        </b-col>
                    </b-row>


                    <!-- Form Actions -->
                    <hr v-if="!isDetail">
                    <div v-if="!isDetail" class="text-right mt-2">
                        <b-button :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                            class="mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                            variant="outline-secondary" @click="closeModal">
                            Cancelar
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

    </div>
</template>

<script>
import _ from "lodash";
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip, 
    VBTooltip,
    VBToggle,
    BSpinner,
    BSidebar,
    BFormCheckbox
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import "animate.css";

import RolesProvider from "@/providers/Roles";
const RolesResource = new RolesProvider();

import PermisosProvider from "@/providers/Permissions";
const PermisosResource = new PermisosProvider();

if (process.env.MIX_FALLBACK_LOCALE === "es") {
    localize('es');
}

export default {
    name: 'RoleList',
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BInputGroupPrepend, 
        BInputGroupAppend, 
        BInputGroup,
        BFormGroup,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BSpinner,
        BTooltip, 
        VBTooltip,
        VBToggle,
        vSelect,
        BFormCheckbox,
        BSidebar,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            hideSidebar: false,
            roles: [],
            role: {
                id: null,
            },
            perPageOptions: [10, 25, 50, 100],
            perPage: 10,
            currentPage: 1,
            totalRoles: 0,
            columns: [               
                {
                    key: "id",
                    label: 'ID',
                }, 
                {
                    key: "name",
                    label: 'Nombre',
                },                
                { key: "actions", label: 'Acciones' },
            ],
            sortBy: "id",
            sortDesc: true,
            searchQuery: null,
            from: 0,
            to: 0,
            loading: false,
            isEdit: false,
            isActiveModal: false,
            isDetail: false,
            titleModal: "Alta de Rol",
            permissions: [],
        };
    },
    computed: {
       listRoles () {            
            let result = this.searchQuery ? this.roles.filter(o =>
                    o.nombre.toLowerCase().includes(this.searchQuery.toLowerCase())                    
                ) : this.roles;
            return result
       }
    },
    watch: {
       
    },
    async mounted() {
        await this.getPermissions()
        await this.getRoles();
    },
    methods: {
        async getPermissions () {
            this.loading = true
            const { data } = await PermisosResource.index()
            this.loading = false

            console.log(data)

        },
        closeModal() {
            this.isActiveModal = false
            this.role = {}
            this.titleModal = `Alta de Rol`
        },
        async onSubmit () {
            this.role.roleName = this.role.name
            try {
                this.loading = true
                const { data } = this.isEdit ? await RolesResource.update(this.role.id, this.role) : await RolesResource.store(this.role);
                this.loading = false
                if (data.isSuccesful) {
                    this.success(data.message);
                    await this.getRoles()                    
                    this.isActiveModal = false
                    this.role = {}                    
                } else {
					this.danger(data.message);
                }
            } catch (e) {
                this.loading = false
				if (e.response.status === 422) {
                    this.danger(this.getFirstValidationError(e.response.data.errors), "Error", "AlertCircleIcon");
                } else {
                    this.danger(e.message, "Error", "AlertCircleIcon");
                }
            }
        },       
        editRole(item) {
            this.isEdit = true
            this.role = {...item}
            this.isActiveModal = true
            this.titleModal = `Editar Rol`
        },
        async getRoles() {
            this.loading = true;
            const { data } = await RolesResource.index();
            this.loading = false;
            this.roles = data;
        },
        deleteRoles(item) {
            console.log(item)
            this.$swal({
                title: `¿Desea eliminar el registro?`,
                text: `¡Esto no se puede revertir!`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: `Continuar`,
                cancelButtonText: `Cancelr`,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await RolesResource.destroy(item.id)
                        this.loading = false

                        if (data.isSuccesful) {
                            this.success(data.message)
                            this.roles = this.roles.filter(rol => rol.id !== item.id)
                        } else {
                            this.$swal.showValidationMessage(
                                `Request failed: ${data.message}`
                            );
                        }
                    }catch(e) {
                        this.$swal.showValidationMessage(
                            `Request failed: ${e}`
                        );
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
