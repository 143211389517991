import axios from '@/libs/axios'

export default class Permissions {
    
    index () {
        return axios.get(`/Cuentas/ObtenerPermisos`);
    }

    getPermissions (rolId) {
        return axios.get(`/Cuentas/ObtenerPermisosPorRol/${rolId}`);    
    }
    
    AssingPermissions(formData) {
        return axios.post(`/Cuentas/AsignarPermisos`, formData);    
    }

    AssingUserWarehouse(formData) {
        return axios.post(`/Cuentas/AsignarAlmacen`, formData);
    }
    
    store(payload) {
        return axios.post(`/Cuentas/RegistrarPermiso`, payload)
    }
}